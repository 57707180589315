<template>
    <div class="potential">
<!--        <van-nav-bar-->
<!--                title="收房申请"-->
<!--                left-arrow-->
<!--                class="nav_bar_title"-->
<!--                fixed-->
<!--                placeholder @click-left="leftReturn"-->
<!--        />-->
        <rxNavBar title="收房申请" ></rxNavBar>
        <div class="text-right">
            <span class="span">申请时间：</span><span class="span">{{orders.addTime}}</span>
        </div>
        <div class="panel">
            <div class="state" v-if="orders.approvalStatus == 1">已通过</div>
            <div class="state efficacy" v-else>已驳回</div>
            <div class="title">
                <p class="van-multi-ellipsis--l2" style="margin: 0px 0px" :class="orders.state == 0?'faild':null">
                    {{orders.roomDetailedAddress}}</p>
                <label class="span" style="margin: 0 8px 0 0">{{orders.cityProper}}</label>
                <span class="span">|</span>
                <label class="span" style="margin: 0 8px">{{orders.businessCircleName}}</label>
                <span class="span">|</span>
                <label class="span" style="margin: 0 8px">{{orders.estateName}}</label>
            </div>
        </div>
        <div class="panel" v-if="imagesList.length>0">
            <div class="photo_show" ref="wrapperBox">
                <div class="content">
                    <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
                        <van-image
                                width="100"
                                height="75"
                                fit="contain"
                                radius="10"
                                :src="url"
                        />
                        <div class="num">
                            {{imagesList.length}}图
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="panel">
            <div class="panelHeader">
                <div class="panelInfo one"><label>面积：<span
                        class="span">{{orders.builtUpArea}}㎡</span></label></div>
                <div class="panelInfo"><label>改造风格：<span class="span">{{orders.decorationStyle}}</span></label>
                </div>
            </div>
            <div>
                <div class="panelInfo one"><label>楼层：<span
                        class="span">{{orders.floor}}/{{orders.totalFloor}}楼</span></label></div>
                <div class="panelInfo"><label>装修期：<span class="span">{{orders.renovationTime?orders.renovationTime+'天':'暂无'}}</span></label>
                </div>
            </div>
            <div>
                <div class="panelInfo one"><label>朝向：<span class="span">{{orders.orientation}}</span></label>
                </div>
                <div class="panelInfo"><label>租金：<span class="span">{{orders.rentMoney}}元/月</span></label></div>
            </div>
            <div class="panelFooter">
                <div class="panelInfo one"><label>户型改造：<span
                        class="span">{{orders.bedroomCount}}+{{orders.changHouseNum}}</span></label></div>
                <div class="panelInfo"><label>总面周期：<span class="span">{{orders.noFeeTime?orders.noFeeTime+'天':'暂无'}}</span></label></div>
            </div>
        </div>
        <div class="panel">
            <div class="panelInfo2">
                <p>收房描述：</p>
                <span class="span">{{orders.remarks?orders.remarks:'暂无'}}</span>
            </div>
        </div>
        <div class="checkInfos">审核信息</div>

        <!--已通过-->
        <div class="bottom-check" v-for="(item,index) in approvalHistoryInfoList" :key="index">
            <div class="bottom-text" v-if="item.approvalResult=='0'">
                <span class="bottom-text-left">指导价格：</span>
                <span class="bottom-text-right text-red">{{orders.guidancePrice}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">审核人：</span>
                <span class="bottom-text-right">{{item.approvalStaffName}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">审核时间：</span>
                <span class="bottom-text-right">{{item.addTime}}</span>
            </div>
            <div class="bottom-text" v-if="item.approvalResult=='0'">
                <span class="bottom-text-left ">审核结果：</span>
                <span class="bottom-text-right text-green">{{item.approvalResultStr}}</span>
            </div>
            <div class="bottom-text" v-if="item.approvalResult=='1'">
                <span class="bottom-text-left">审核结果：</span>
                <span class="bottom-text-right text-red">{{item.approvalResultStr}}</span>
            </div>
            <div class="bottom-text" v-if="item.approvalResult=='2'">
                <span class="bottom-text-left">审核结果：</span>
                <span class="bottom-text-right">{{item.approvalResultStr}}</span>
            </div>
            <div class="bottom-text" v-if="item.approvalResult=='1'">
                <span class="bottom-text-left">驳回原因：</span>
                <span class="bottom-text-right">{{item.dictionaryTitle}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">审核意见：</span>
                <span class="bottom-text-right">{{item.remarks?item.remarks:'暂无'}}</span>
            </div>
        </div>

<!--            &lt;!&ndash;已驳回&ndash;&gt;-->
<!--            <div class="bottom-check" v-if="orders.approvalStatus=='2'">-->
<!--                <div class="bottom-text">-->
<!--                    <span class="bottom-text-left">审核人：</span>-->
<!--                    <span class="bottom-text-right">{{approvalHistoryInfoList.approvalStaffName}}</span>-->
<!--                </div>-->
<!--                <div class="bottom-text">-->
<!--                    <span class="bottom-text-left">审核时间：</span>-->
<!--                    <span class="bottom-text-right">{{approvalHistoryInfoList.addTime}}</span>-->
<!--                </div>-->
<!--                <div class="bottom-text">-->
<!--                    <span class="bottom-text-left">审核结果：</span>-->
<!--                    <span class="bottom-text-right text-red">{{approvalHistoryInfoList.approvalResultStr}}</span>-->
<!--                </div>-->
<!--                <div class="bottom-text">-->
<!--                    <span class="bottom-text-left">驳回原因：{{approvalHistoryInfoList.dictionaryTitle}}</span>-->
<!--                    <span class="bottom-text-right"></span>-->
<!--                </div>-->
<!--                <div class="bottom-text">-->
<!--                    <span class="bottom-text-left">审核意见：{{approvalHistoryInfoList.remarks}}</span>-->
<!--                    <span class="bottom-text-right"></span>-->
<!--                </div>-->
<!--            </div>-->

    </div>

</template>
<script>
    import {Image as VanImage, ImagePreview, NavBar} from 'vant'
    import BScroll from "better-scroll";
    import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
    import {
        colllectRoomDetail
    } from '../../../getData/getData';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

    export default {
        data() {
            return {
                orders: {},
                imagesList: [

                ],
                //申请历史列表
                approvalHistoryInfoList:[],
                id:''
            }
        },
        components: {
            [NavBar.name]: NavBar,
            [VanImage.name]: VanImage,
            [ImagePreview.Component.name]: ImagePreview.Component,
            [BScroll.name]: BScroll,
            rxNavBar

        },
        mounted: function () {
            this.id = this.$route.query.id
            // 初始化
            this.initData()
        },
        methods: {
            //初始化页面数据
            initData:function(){
                var that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.id = that.id
                //console.log(initData)
                colllectRoomDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        //console.log(response.data.data)
                        let data = response.data.data
                        that.orders = data.item
                        that.approvalHistoryInfoList = data.approvalHistoryInfoList
                        //加载图片信息

                        let list = data.explorationList
                        if(list){
                            // let length = data.explorationList.length-1
                            // list = data.explorationList[length].children
                            // for(let i=0;i<list.length;i++){
                            //     that.imagesList.push(list[i].path)
                            // }
                            for(let i=0;i<data.explorationList.length;i++){
                                list = data.explorationList[i].children
                                if(list&&list.length>0){
                                    for(let j=0;j<list.length;j++){
                                        that.imagesList.push(list[j].path)
                                    }
                                }
                            }
                            if(that.imagesList.length>0){
                                that.$nextTick(() => {
                                    if (!that.scroll) {
                                        that.scroll = new BScroll(that.$refs.wrapperBox, {
                                            scrollX: true,
                                            eventPassthrough: 'vertical'
                                        })
                                    }
                                })
                            }
                        }
                    })
                })
            },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            previewImg(start) {
                ImagePreview(
                    {
                        images: this.imagesList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            }
        },
        created() {
        }
    }
</script>
<style lang="less" scoped>
    .owner-info {
        background-color: white;
        border-radius: 8px;
        height: 20px;
        width: 100%;
    }

    .text-right {
        display: flex;
        height: 22px;
        line-height: 22px;
        align-items: center;
        padding: 0px 14px;
        float: right;
    }

    .span {
        font-size: 14px;
        color: #999999;
        line-height: 22px;
        font-weight: normal;
    }

    .span-red {
        font-size: 14px;
        color: red;
        line-height: 22px;
        font-weight: bolder;
    }

    .span-bolder {
        font-size: 14px;
        line-height: 22px;
        font-weight: bolder;
    }

    @radius: 8px;
    @border: 1px solid rgb(244, 244, 244);
    .potential {
        width: 100%;
        background-color: rgb(250, 250, 250);
        font-size: 12px;
        padding: 20px 0 0 0;
        box-sizing: border-box;
        overflow: hidden;

        .line {
            font-size: 20px;
        }

        .bordBottom {
            border-bottom: @border;
        }

        .panel {
            width: 345px;
            margin: 0px auto 15px;
            background-color: rgb(255, 255, 255);
            border-radius: @radius;
            position: relative;
            font-size: 15px;
            text-align: left;
            overflow: hidden;

            &.withMargin {
                margin: 25px 0;
            }

            &:last-of-type {
                margin-bottom: 0px;
            }

            .state {
                border: 1px solid transparent;
                font-size: 20px;
                font-weight: bold;
                height: 30px;
                line-height: 30px;
                width: 80px;
                background-color: rgb(47, 210, 172);
                border-top-left-radius: @radius;
                border-bottom-right-radius: @radius;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;

                &.efficacy {
                    background-color: red;
                }
            }
            .panelInfo {
                padding: 0 14px;
                font-size: 15px;

                &.one {
                    width: 40%;
                    float: left;
                }

                label {
                    font-weight: bolder;

                    .fitment {
                        color: #f00;
                    }

                    span {
                        font-weight: normal;
                        color: rgba(153, 153, 153, 1);
                    }
                }

            }

            .panelHeader {
                padding-top: 14px;
            }

            .panelFooter {
                padding-bottom: 14px;
            }

            .panelInfo2 {
                border-bottom: @border;
                padding: 16px 14px;
                font-size: 15px;

                p {
                    font-weight: bolder;
                    margin: 0 0 8px 0;

                    .fitment {
                        color: #f00;
                    }

                    span {
                        font-weight: bolder;
                        color: red;
                    }
                }
            }

            .title {
                width: 315px;
                padding: 0px 14px 8px 14px;
                font-weight: bolder;
                color: rgb(0, 0, 0);
                border-bottom: @border;
                font-size: 15px;

                .house_title {
                    margin-top: 20px;
                }

                span {
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }
        }

        .imgCount {
            background-color: black;
            opacity: 0.5;
            position: absolute;
            font-size: 9px;
            left: 12px;
            top: 14px;
            z-index: 1;
            color: white;
            width: 30px;
            text-align: center;
            border-radius: 10px;
        }

        .houseImage {
            width: 97px;
            height: 73px;
            margin-top: 11px;
            margin-left: 8px;
            margin-bottom: 11px;
            float: left;
            display: block;
        }

        .middle {
            overflow: hidden;
            height: auto;
        }

        .displayBlock {
            overflow: hidden;
            position: relative;
            background-color: white;
            border-radius: 9px;
            width: 9rem;
            height: auto;
            margin: auto;
        }

        // 图片墙
        .photo_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            .num {
                position: absolute;
                left: 4px;
                top: 6px;
                font-size: 10px;
                background-color: rgb(0, 0, 0);
                opacity: .1;
                color: rgb(255, 255, 255);
                padding: 2px 6px;
                display: inline-block;
                border-radius: 10px;
                font-weight: bolder;
                line-height: 10px;
            }
        }
        .checkInfos{
            margin: 30px 0 6px 15px;
            height: 17px;
            line-height: 17px;
            color: rgba(255, 93, 59, 1);
            font-size: 12px;
            text-align: left;
            font-family: PingFangSC-Semibold
        }
        .bottom-check{
            width: 345px;
            margin: 0 15px 15px 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: rgb(255, 255, 255);
            border-radius: @radius;
            position: relative;
            font-size: 15px;
            text-align: left;
            overflow: hidden;
        }
        .bottom-text{
            height: auto;
            line-height: 24px;
            margin-left: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .bottom-text-left{
            font-weight: bold;
        }
        .bottom-text-right{
            font-size: 14px;
            color: #999999;
        }
        .text-red{
            color: #FF7357;
        }
        .text-green{
            color: #4DC7AF;
        }
    }
</style>
